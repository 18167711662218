import { getOwnTeamsQueryFragment } from "../query-fragments/team.fragments";
import * as fs from "fs";
import moment from "moment";
import _ from "lodash";
import { CalendarEntry, sandboxSoccerGameId, SoccerGameEvent, SoccerGameEventType } from "@ollie-sports/models";
import { getUniversalHelpers } from "../helpers";
import { common__client__getGenerator } from "../api";

export async function sandbox__test01() {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let bundle = await h.SoccerGameEventBundle.getDoc(common__client__getGenerator().SoccerGameEventBundleId(sandboxSoccerGameId));
  if (!bundle) {
    process.exit(1);
  }
}
// i18n certified - complete
